.version-container{
  position:fixed;
  color: black;
  background-color: #d6d6d6;
  z-index: 2;
  bottom: 0;
  right: 0;
  font-size: 0.8rem;
  padding: 0.25rem 1rem;
  border-top-left-radius: 0.5rem;
}