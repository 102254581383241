.customAlertDiv {
  z-index: 501;
  width: 600px;
  height: 370px;
  padding: 15px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.customAlertText{
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  line-height: 1.25rem;
  margin: 0 !important;
  color: dimgray;
}

.customAlertButtonDiv {
  display: flex;
  flex-direction: row;
}


.customAlertBtn {
  color: white;
  border: none;
  border-radius: 5px;
  margin: 10px;
  font-size: 18px;
  width: 200px;
  -moz-transition: all .1s ease-in;
  -o-transition: all .1s ease-in;
  -webkit-transition: all .1s ease-in;
  transition: all .1s ease-in;
}

/* create class named blue for customAlertBtn */
.customAlertBtn.green {
  background-color: #5dbf40;
}


.customAlertBtn.green:hover {
  background-color: #54aa39;
}

.customAlertBtn.red {
  background-color: #d51c1c;
  color: white;
}

.customAlertBtn.red:hover {
  background-color: #b91919;
  color: white;
}

.customAlertBtn.borderlessRed {
  background-color: transparent;
  color: red;
  border: 1px solid red;
  border-radius: 5px;
}

.customAlertBtn.borderlessRed:hover{
  background-color: rgba(255, 0, 0, 0.184);
}