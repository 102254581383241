body{
  margin: 0;
}

.login{
  height: 100vh;
  width: 100vw;
  background-image: url("./Images/bg-matriz.jpg");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Helvetica Neue', sans-serif;
}

.overlay{
  background-color: rgba(255,255,255);
  width: 50%;
  height: 50%;
  border-radius: 8px;
  padding: 50px;
  display: flex;
  align-items: center;
}

.title{
  font-size: 1.25rem;
  font-weight: bold;
}

.form{
  max-width: 280px;
}

@media(max-width: 1400px){
  .overlay{
    width: 75%;
    height: 75%;
  }
}

@media(max-width: 767px){
  .overlay{
    padding: 20px;
    width: 90%;
    height: fit-content;
  }
}

@media(min-width: 1000px){
  .text{
    padding-left: 48px;
  }
}