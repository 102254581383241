//Admin #74c15d
//Admin active #82c76e
//Cliente #406d93
//Cliente active #537c9e
//Consultor #525252
//Consultor active #646464

$font-family-base: 'sans-serif';
/*$theme-colors: (
  "primary": #386EFD,
  "dark-light": #E4E4E4
);*/

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

.custom-nav{
  color: white !important;
  
  div{
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.admin-active{
  &.active{
    background-color: #82c76e !important;
  }
}

.client-active{
  &.active{
    background-color: #537c9e !important;
  }
}

.consultant-active{
  &.active{
    background-color: #646464 !important;
  }
}

.custom-title{
  font-size: 1.25rem !important;
}

.custom-dropdown{
  .dropdown-toggle{
    background-color: white !important;
  }
}

.inactive-badge{
  background-color: #E4E4E4 !important;
  color: #E4E4E4 !important;
}

.success-badge{
  background-color: #198754 !important;
  color: #198754 !important;
}

.principal-badge{
  background-color: #406D93 !important;
  color: #406D93 !important;
}

.icon-badge{
  background-color: #525252 !important;
  padding: 0.5rem !important;
  border-radius: 1rem !important;
}

.icon{
  height: 1.3rem;
  width: 1.3rem;
  color: white;
}

.icon-big{
  height: 3.5rem;
  width: 3.5rem;
}

.custom-span{
  color: #959595 !important;
}

.download-button{
  text-decoration: none;
  color: var(--bs-secondary-rgb);

  &:hover{
    color: var(--bs-btn-hover-color);
  }
}

.custom-toast-container{
  height: 100vh;
  position: fixed !important;
  top: 0;
  right: 0;
}

.custom-table-bg{
  background-color: #FDEEBB;
}

.selected-status{
  padding: 5px;
  border: 5px solid blue;
  border-radius: 8px;
}

.cancel-button{
  .icon{
    color: #DC3545;
  }

  &:hover{
    .icon{
      color: white;
    }
  }
}

.terms::-webkit-scrollbar {
  width: 5px;
}

.terms::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.terms::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px !important;
}

.terms::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 768px) {
  .align-banner-text{
    text-align: center;
  }
}

@import "~bootstrap/scss/bootstrap.scss";