.task-container {
  width: 100%;
  height: fit-content;
  background-color: white;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(var(--amount),1fr);
  grid-template-rows: 1fr;

  .task{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 60px 1fr;

    .task-up{
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;

      .task-line{
        flex-grow: 1;
        height: 5px;
        background-color: #74C15D;

        &.inactive{
          background-color: white;
        }

        &.unchecked{
          background-color: #DDD;
        }
      }

      .task-icon{
        width: 55px;
        height: 55px;
        flex-basis: auto;
        border-radius: 50%;
        background-color: #74C15D;

        &.unchecked{
          width: 40px;
          height: 40px;
          border: 5px solid #DDD;
          background-color: white;
        }
      }
    }

    .task-down{
      width: 100%;
      height: 100%;
      text-align: center;
      padding: 10px;
      color: #959595;
      font-weight: 600;
      font-size: 0.8rem;

      &.finished{
        color: #74C15D;
      }
    }
  }
  }

